/* eslint-disable max-lines */
// iconic onboarding
export const ICONIC_ONBOARDING_QUESTION = 'Iconic Onboarding - Question';
export const ICONIC_ONBOARDING_MENTOR_ASSIGNED =
  'Iconic Onboarding - Mentor Assigned';
export const ICONIC_ONBOARDING_MENTOR_ASSIGN_FAILED =
  'Iconic Onboarding - Mentor Assign Failed';
export const ICONIC_ONBOARDING_MENTOR_WELCOME_SESSION_CLICKED =
  'Iconic Onboarding - Mentor Welcome Session Complete Clicked';
export const ICONIC_ONBOARDING_START_LEARNING =
  'Iconic Onboarding - Start Learning Clicked';
export const WEB_PAGE_VIEW = 'Web-page-view';
export const HOME_NAVIGATION_CLICKED = 'Homepage - Navigation Option Clicked';
export const HERO_CARD_CLICKED = 'Batch - Batch Video Preview';
// subject pill
export const SUBJECT_PILL_CHANGED = 'Subjects - Chip Filter Clicked';

export const COURSE_CARD_CLICKED = 'Course - Course Clicked';
// subscription
export const LEARNING_FOR_FREE_CLICKED = 'Learning for free clicked';
export const SUBSCRIPTION_PLAN_CLICKED = 'Checkout - Started';
// scholarship banner
export const SCHOLARSHIP_BANNER_VIEWED = 'Scholarship Test - Card Viewed';
// banner
export const BANNER_VIEWED = 'Banner - Banner Viewed';
export const BANNER_CLICKED = 'Banner - Banner Clicked';
// offline cemtres
export const OFFLINE_CENTRE_KNOW_MORE = 'Offline Centres - Know More';
export const VIEW_OFFLINE_BATCHES = 'Offline - Checkout Started';
export const CENTRE_NEAR_YOU_CLICKED = 'Centre Near You Clicked';
// checkout

export const CHECKOUT_STARTED = 'Checkout - Started';
export const CHECKOUT_OFFER_VIEWED = 'Checkout - Offers Viewed';
// subscription-plans
export const ICONIC_SUBSCRIPTION = 'Iconic Subscription';
export const PLUS_SUBSCRIPTION = 'Plus Subscription';
export const LITE_SUBSCRIPTION = 'Lite Subscription';
// SEE ALL
export const POPULAR_SUBJECT_SELECTED = 'Popular Subject Selected';
export const SEE_ALL_CLICKED = 'See All - Clicked';
// FPU
export const FPU_DISPLAY = 'FPU - Bottom Sheet Displayed';
export const FPU_UNLOCK_CLICKED = 'FPU - Unlock Clicked';
export const FPU_UNLOCKED = 'FPU - Unlocked';
export const FPU_SHOW_DEFAULT = "FPU - 'Don't Have One' Clicked";

// Sales
export const SALES_LMP_COUNSELLOR_STATUS_CHANGE =
  'Sales LMP - Counsellor Status Change';
export const SALES_LMP_SESSION_REQUEST_POPUP =
  'Sales LMP - Session Request Popup';
export const SALES_LMP_ENTER_SESSION_CLICKED =
  'Sales LMP - Enter Session Clicked';
export const SALES_LMP_COUNSELLOR_FEEDBACK_SUBMITTED =
  'Sales LMP - Counsellor feedback submitted';

export const GTM_TAP_ENROLL = 'GTM - Tap Enroll';
export const GTM_ACTIVITY_CLICKED = 'GTM - Activity Clicked';

// K12 Landing Page
export const K12_LANDING_BOOKING_ACTIVITY_STARTED =
  'K12 Landing Page - Booking Activity Started';
export const K12_LANDING_OTP_VERIFIED = 'K12 Landing Page - OTP Verified';
export const K12_LANDING_PAGE_ACTIVITY_CONFIRMED =
  'K12 Landing Page - Activity Confirmed';
export const K12_LANDING_PAGE_DOWNLOAD_APP_CLICKED =
  'K12 Landing Page - Download App Clicked';

// TTU
export const TTU_FAB_CLICK = 'TTU - FAB Icon Clicked';
export const TTU_POP_UP_CLICK = 'TTU - Talk to Unacademy Clicked';
export const TTU_CALL_CONFIRM = 'TTU - Call Confirmed';
export const TTU_ANSWER_QUESTIONS = 'TTU - Questions Answered';
export const TTU_NUMBER_CLICKED = 'TTU - Inbound Number Clicked';

// Course Related Events
export const COURSE_ENROLLED = 'Course Enrolled';
export const COURSE_UNENROLLED = 'Course Unenrolled';
export const COURSE_VIEWED = 'Course Viewed';
export const COURSE_SHARED = 'Course Shared';
export const COURSE_ABOUT = 'Course About';
export const COURSE_UPDATES = 'Course Updates';
export const COURSE_PREVIEW = 'Course Video Preview';
export const COURSE_UPDATES_FILE_DOWNLOAD = 'Course Updates File Download';

// Test Series Related Events
export const TEST_SERIES_VIEWED = 'Test Series Viewed';
export const TEST_SERIES_SHARED = 'Test - Test Shared';
export const TEST_SERIES_ABOUT = 'Test Series About';
export const TEST_SERIES_UPDATES = 'Test Series Updates';
export const TEST_SERIES_ENROLLED = 'Test Series Enrolled';
export const TEST_SERIES_UNENROLLED = 'Test Series Unenrolled';
export const TEST_SERIES_UPDATES_FILE_DOWNLOAD =
  'Test Series Updates File Download';
export const TEST_SERIES_SECTION_CLICKED = 'Test Series - Section Clicked';

// Batch Related events
export const BATCH_UNENROLLED = 'Batch Unenrolled';
export const BATCH_ENROLLED = 'Batch Enrolled';
export const BATCH_VIEWED = 'Batch - Batch Viewed';
export const BATCH_UPDATE = 'Batch Updates';
export const BATCH_SHARED = 'Batch Shared';
export const BATCH_BROCHURE_DOWNLOAD = 'Batch brochure download';
export const BATCH_BROCHURE_DOWNLOADED = 'Batch - Batch Brochure Downloaded';
export const BATCH_ABOUT = 'Batch About';
export const BATCH_SCHEDULE = 'Batch - Batch Schedule Viewed';
export const BATCH_PREVIEW = 'Batch - Batch Video Preview';
export const BATCH_SYLLABUS_VIEWED = 'Batch Syllabus Viewed';
export const BATCH_UPDATE_FILE_DOWNLOAD = 'Batch Updates File Download';
export const BATCH_NOTIFY_CLICKED = 'Batch - Batch Notify Me Clicked';
export const BATCH_ENROLL_ATTEMPTED = 'Batch - Enroll Attempted';
export const BATCH_ENROLL_POPUP_CLICKED = 'Batch - Enroll Popup Clicked';
export const BATCH_ENROLL_FEEDBACK_CLICKED = 'Batch - Enroll Feedback Clicked';
export const BATCH_UNENROLL_FEEDBACK_CLICKED =
  'Batch - Unenroll Feedback Clicked';
export const BATCH_MANAGE_BATCH = 'Batch - Manage Batch';
export const BATCH_UNENROLL_ATTEMPTED = 'Batch - Unenroll Attempted';
export const BATCH_SYLLABUS_DETAILS_VIEWED =
  'Batch - Batch Syllabus Details Viewed';

// Educator Related Events
export const EDUCATOR_PROFILE_VIEWED = 'Educator Profile Viewed';
export const EDUCATOR_FOLLOWED = 'Educator Followed';
export const EDUCATOR_UNFOLLOWED = 'Educator Unfollowed';
export const EDUCATOR_LEADERBOARD_VIEWED = 'Educator Leaderboard Viewed';
export const EDUCATOR_ABOUT = 'Educator About';
export const EDUCATOR_HATS_VIEWED = 'Educator Hats Viewed';

// Syllabus Events
export const SYLLABUS_CARD_CLICKED = 'Syllabus - Card Clicked';
export const SYLLABUS_TAB_CLICKED = 'Syllabus - Section Clicked';
export const SYLLABUS_GET_STARTED_CLICKED = 'Syllabus - Get Started Clicked';
export const SYLLABUS_SEE_ALL_CLICKED = 'See All - Clicked';
export const BROWSE_FILTER_APPLIED = 'Browse - Filter Applied';
export const SYLLABUS_MENU_CLICKED = 'Syllabus - Quick Chapter Menu Clicked';
export const SYLLABUS_LOCKED_ITEM_CLICK =
  'Locked Type Activation - Get Subscription Shown';
export const SYLLABUS_PURCHASE_DIALOG_MAY_BE_LATER =
  'Locked Type Activation - Get Subscription Closed With May Be Later';
export const SYLLABUS_PURCHASE_DIALOG_TAP_OUT =
  'Locked Type Activation - Get Subscription Closed With Tap Out';
export const SYLLABUS_PRACTICE_CLICKED = 'Syllabus - Practice Clicked';

export const VIDEO_LESSONS_TAB_CLICKED = 'Video Lessons - Tab Clicked';
export const TOPIC_SUBJECT_FILTER_CLICKED =
  'Video Lessons Subject Filter Applied';
export const TOPIC_EDUCATOR_FILTER_CLICKED =
  'Video Lessons Educator Filter Applied';

// FEEDBACK EVENTS
export const PLUS_HOME_FEEDBACK_SUBMITTED = 'Plus Subscription Rating';
export const SUBSCRIPTION_FEEDBACK_CARD_SUBMITTED =
  'Subscription Feedback Card - Submitted';
export const GTP_SUBSCRIPTION_FEEDBACK_CARD_SUBMITTED =
  'GTP Subscription Rating';

// Planner events
export const PLANNER_CALENDAR_DATE_SELECTED =
  'Planner - Calendar Date Selected';
export const PLANNER_CALENDAR_VIEWED = 'Planner - Calendar Viewed';
export const PLANNER_CARD_CLICKED = 'Planner - Card Clicked';
export const PLANNER_CARD_VIEWED = 'Planner - Card Viewed';
export const PLANNER_EVENT_CLICKED = 'Planner - Event Clicked';
export const PLANNER_EVENT_PRACTICE_CLICKED =
  'Planner - Event Practice Clicked';
export const PLANNER_CONTINUE_NUDGE_CLICKED =
  'Planner - Continue Nudge Clicked';
export const PLANNER_SCROLLED = 'Planner - Scrolled';

export const ADD_TO_PLANNER_TRY_NOW_CLICKED =
  'Add To Planner - Try Now Clicked';
export const ADD_TO_PLANNER_SKIP_CLICKED = 'Add To Planner - Skip Clicked';
export const ADD_TO_PLANNER_VIEWED = 'Add To Planner - Viewed';
export const ADD_TO_PLANNER_CLICKED = 'Add To Planner - Clicked';
export const ADD_TO_PLANNER_EDIT_PREFERENCES_CLICKED =
  'Add To Planner - Edit Preferences Clicked';
export const ADD_TO_PLANNER_REMOVED = 'Add To Planner - Removed';
export const ADD_TO_PLANNER_CUSTOMISE_CLICKED =
  'Add To Planner - Preferences Customisation Clicked';
export const ADD_TO_PLANNER_PREFERENCE_QUESTION =
  'Add To Planner - Preferences Question';

export const PLANNER_VIDEO_CARD_CLICKED = 'Video Card - Clicked';
export const PLANNER_VIDEO_CARD_VIEWED = 'Video Card - Viewed';
export const SUBSCRIPTION_FEEDBACK_CARD_VIEWED =
  'Subscription Feedback Card - Viewed';
export const SUBSCRIPTION_FEEDBACK_CARD_CLICKED =
  'Subscription Feedback Card - Clicked';

// Groups events
export const GROUP_LEARNER_PROFILE_CLICKED = 'Group - Learner Profile Clicked';
export const GROUP_PROFILE_ACTIVITY_CLICKED =
  'Group - Profile Activity Clicked';
export const GROUP_JOIN_CLICKED = 'Group - Join Group Clicked';
export const GROUP_JOIN_INVITATION_VIEWED = 'Group - Join Invitation Viewed';
export const GROUP_JOIN_INVITATION_CLICKED = 'Group - Join Invitation Clicked';
export const GROUP_INVITE_CLICKED = 'Group - Invite Clicked';
export const GROUP_CHANGE_CLICKED = 'Group - Group Change Clicked';
export const GROUP_CHANGE_RESTRICTED = 'Group - Group Change Restricted';
export const GROUP_CHANGE_CONFIRMED = 'Group - Group Change Confirmed';
export const GROUP_CHANGED = 'Group - Group Changed';
export const GROUP_JOIN_RESTRICTED = 'Group - Group Join Restricted';
export const GROUP_WEEKLY_SCOREBOARD_CLICKED =
  'Group - Weekly Scoreboard Clicked';
export const GROUP_WEEKLY_LEADERBOARD_CLICKED =
  'Group - Weekly Leaderboard Clicked';
export const GROUP_LEADERBOARD_CLOSED = 'Group - Group Leaderboard Closed';

// Doubts and solutions Browse
export const BOOK_VIEWED = 'Ask a Doubt - Book Clicked';
export const PYQ_VIEWED = 'Ask a Doubt - PYQ Clicked';
export const CHAPTER_SELECTED = 'Ask a Doubt - Chapter Selected';
export const PYQ_SELECTED = 'Ask a Doubt - PYQ Subject Selected';
export const AAD_TAB_CLICKED = 'Ask a Doubt - Tab Clicked';

// Settting pages
export const EDIT_PROFILE_DISALLOWED =
  'Learner Profile - Edit Profile Disallowed';

// Goal Keys
export const GOAL_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_invited',
  'is_verified',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date'
];

// Syllabus related keys
export const SYLLABUS_CLICKED_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_invited',
  'is_verified',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date',
  'topology_id',
  'topology_level',
  'topology_name'
];

// Educatore Related Keys

export const EDUCATOR_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_invited',
  'is_verified',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date',
  'educator_uid',
  'educator_username',
  'last_primary_source',
  'last_primary_source_section',
  'path'
];

// Batch event related keys

export const BATCH_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_enrolled',
  'is_invited',
  'is_verified',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date',
  'educator_uid',
  'educator_username',
  'last_primary_source',
  'last_primary_source_section',
  'path',
  'batch_uid',
  'batch_title',
  'start_date',
  'end_date'
];

// Course event related keys
export const COURSE_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_invited',
  'is_verified',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date',
  'educator_uid',
  'educator_username',
  'last_primary_source',
  'last_primary_source_section',
  'path',
  'course_uid',
  'course_title',
  'start_date',
  'end_date',
  'is_enrolled'
];

// Test Series Event Related Keys
export const TEST_SERIES_KEYS = [
  'goal_uid',
  'goal_name',
  'learner_uid',
  'learner_username',
  'language',
  'os',
  'os_version',
  'app_version',
  'platform',
  'browser',
  'is_invited',
  'is_verified',
  'is_enrolled',
  'is_subscription_active',
  'days_since_joining',
  'subscription_duration',
  'subscription_end_date',
  'educator_uid',
  'educator_username',
  'last_primary_source',
  'last_primary_source_section',
  'path',
  'test_series_uid',
  'test_series_title',
  'start_date',
  'end_date'
];

// Referral LPSS Props
export const REFERRAL_PLUS_HOME = 'Plus Home Tab';
export const REFERRAL_MY_SCHEDULE = 'Schedule Tab';
export const REFERRAL_NOTIFICATIONS = 'Updates Tab';
export const REFERRAL_PLAYER_DIALOG = 'Refer & earn Bottomsheet';
export const REFERRAL_TOPBAR_TOUCHPOINT = 'Top bar touchpoint';
export const REFERRAL_NUDGE_BANNER = 'Referral Nudge Banner';

// Referral Events
export const REFERRAL_TOUCHPOINT_LOADED = 'Refer - Impression';
export const REFERRAL_CODE_COPIED = 'Refer - Code Copied';
export const REFERRAL_KNOW_MORE = 'Refer - Know More';
export const REFERRAL_LIST_VIEW = 'Refer - Referred Users Viewed';
export const REFERRAL_PLAYER_POPUP = 'Refer - Bottom Sheet Displayed';
export const REFERRAL_TOUCHPOINT_CLICKED = 'Refer - Clicked';
export const REFERRAL_TOUCHPOINT_CLOSED = 'Refer - Card Closed';

export const CLX_LESSON_VIEW = 'CLX - Lesson View';
export const CLX_SUCESSFULL_VIEW_FIVE_MINUTES = 'CLX - Successful View 5 Mins';
export const CLX_SUCESSFULL_VIEW_FIFTEEN_MINUTES =
  'CLX - Successful View 15 Mins';
export const CLX_CLASS_FEEDBACK_SUBMITTED = 'CLX - Class Feedback Submitted';
export const CLX_LESSON_PDF_DOWNLOADED = 'CLX - Lesson PDF Downloaded';
export const PDF_DOWNLOAD = 'CLX - Lesson PDF Downloaded';

// Test Events
export const START_TEST_CLICKED = 'Test - Start Test Clicked';
export const TEST_DETAILS_VIEWED = 'Test - Test Details Viewed';
export const TEST_STARTED = 'Test - Test Started';
export const TEST_FINISHED = 'Test - Test Finished';
export const LAB_VALUES_CLICKED = 'Test - Lab Values Clicked';
export const SOLUTION_VIEWED = 'Test - Solutions Viewed';
export const LEADERBOARD_VIEWED = 'Test - Leaderboard Viewed';
export const BULK_SUBMIT_API_FAILED = 'Test - Bulk Submit API failed';
export const FEEDBACK_SUBMITTED = 'Test - Feedback Submitted';
export const FEEDBACK_OPTIONS_SUBMITTED = 'Test - Feedback Options Submitted';
export const OPEN_IN_APP_CLICKED = 'Open in App - CTA Clicked';
export const TEST_PAGE_VIEWED = 'Test Page View';
// Save feature events
export const SAVE_SAVE_CONTENT = 'Save - Save Content';
export const SAVE_UNSAVE_CONTENT = 'Save - Unsave Content';
export const SAVE_SAVE_VIEWED = 'Save - Save Viewed';
export const SAVE_FOLDER_VIEWED = 'Save - Folder Viewed';

// Download PDF over lessons in syllabus
export const DOWNLOAD_PDF = 'CLX - Lesson PDF Downloaded';

// Practice session
export const PRACTICE_SECTION_CLICKED = 'Practice - Practice Section Clicked';
export const PRACTICE_PAGE_FILTERS_APPLIED = 'Practice - Page Filters Applied';
export const PRACTICE_SYLLABUS_CHOSEN = 'Practice - Syllabus Chosen';
export const PRACTICE_EXAM_PAPERS_CLICKED = 'Practice - Exam Papers Clicked';
export const PRACTICE_STARTED = 'Practice - Practice Started';
export const PRACTICE_FINISHED = 'Practice - Practice Finished';
export const PRACTICE_SUMMARY_VIEWED = 'Practice - Summary Viewed';
export const PRACTICE_QUESTION_REVIEW_SUBMITTED =
  'Practice - Question Review Submitted';
export const PRACTICE_QUESTION_REVIEW_REASON_SUBMITTED =
  'Practice - Question Review Reason Submitted';
export const PRACTICE_QUESTION_SKIP_REASON = 'Practice - Question Skip Reason';
export const PRACTICE_FEEDBACK_SUBMITTED = 'Practice - Feedback Submitted';
export const PRACTICE_FEEDBACK_OPTIONS_SUBMITTED =
  'Practice - Feedback Options Submitted';
export const PRACTICE_LANGUAGE_CHOSEN = 'Practice - Language Chosen';

// DDP
export const DPP_SET_REMINDER_CARD_VIEWED = 'DPP - Set Reminder Card Viewed ';
export const DPP_SET_REMINDER_CLICKED = 'DPP - Set Reminder Clicked';
export const DPP_PREFERENCES_QUESTION = 'DPP - Preferences Question';
export const PRACTICE_SETTINGS_CLICKED = 'Practice - Settings Clicked';
export const DPP_SET_REMINDER_FINISHED = 'DPP  - Set Reminder Finished';
export const DPP_REMOVE_DPP_CLICKED = 'DPP - Remove DPP Clicked';
export const DPP_EDIT_REMINDER_TIME_CLICKED =
  'DPP - Edit Reminder Time Clicked';

// Combat
export const COMBAT_LEARN_MORE = 'Combat - Product Learn More';
export const COMBAT_WEB_ENROLL = 'Combat - Web Enroll Touchpoint';
export const COMBAT_ENROLLED = 'Combat - Enrolled';
export const COMBAT_DEAILS_VIEWED = 'Combat - Details Viewed';
export const COMBAT_STARTED = 'Combat - Started';
export const COMBAT_EXIT_REQUESTED = 'Combat - Exit Requested';
export const COMBAT_EXIT_CONFIRMED = 'Combat - Exit Confirmed';
export const COMBAT_FINISHED = 'Combat - Finished';
export const COMBAT_SHARED = 'Combat - Shared';
export const COMBAT_SOLUTION_VIEWED = 'Combat - Solution Viewed';
export const COMBAT_SOLUTION_VIDEO_PLAYED = 'Combat - Solution Video Played';
export const COMBAT_PERFORMANCE_REPORT_DETAILS =
  'Combat - Performance Report Details';
export const COMBAT_PERFORMANCE_REPORT_SHARED =
  'Combat - Performance Report Shared';
export const COMBAT_LEADERBOARD_VIEWED = 'Combat - Leaderboard Viewed';

// Combat features not yet implemented on web
export const COMBAT_QUESTION_REPORTED = 'Combat - Question Reported';
export const COMBAT_SOLUTION_FEEDBACK_SUBMITTED =
  'Combat - Solution Feedback Submitted';
export const COMBAT_SOLUTION_FEEDBACK_CHOICES_SUBMITTED =
  'Combat - Solution Feedback Choices Submitted';
export const COMBAT_GET_MORE_CREDITS_CLICKED =
  'Combat - Get More Credits Clicked';

// Search Revamp Events
export const SEARCH_CLICKED = 'Search - Search Clicked';
export const SEARCH_COMPLETED = 'Search - Search Completed';
export const SEARCH_RESULT_CLICKED = 'Search - Result Clicked';
export const SEARCH_SEE_ALL_CLICKED = 'Search - See All Clicked';
export const RECENT_SEARCH_RESULT_CLICKED = 'Search - Recent Searches Clicked';

export const searchObjectTypes = {
  course: 0,
  batch: 1,
  lesson: 2,
  educator: 3,
  topology: 4,
  testSeries: 5,
  combat: 6
};
// List of FPS (LPS too)

export const SCHEDULE_PAGE = 'Schedule Page';
export const GOAL_PAGE = 'Goal Home';
export const SAVED_PAGE = 'Saved Page';
export const ENROLLMENTS = 'My Enrollments';
export const SYLLABUS = 'Syllabus Home';
export const NOTES = 'Notes Page';
export const TEST_SERIES_PAGE = 'Test Series Page';
export const ALL_TEST_SERIES = 'All Test Series Page';
export const BATCH_PAGE = 'Batch Page';
export const STORE_BATCH_PAGE = 'Store Product Details Page';
export const STORE_TEST_PAGE = 'Store Test Page';
export const COURSE_PAGE = 'Course Page';
export const PLANNER_PAGE = 'Planner';
export const PRACTICE_PAGE = 'Practice: Topic Selection';
export const SETTINGS = 'Settings';
export const HELP_AND_SUPPORT = 'Help & Support';
export const MY_LIBRARY = 'Old Course Library';
export const OTHER_COURSES = 'Old Courses Home';
export const FREE_CLASSES_AND_TESTS = 'Free Classes and Tests';
export const TOPIC_SYLLABUS_PAGE = 'Topic Syllabus Details';
export const TOPIC_COURSES_SYLLABUS_PAGE = 'Topic Courses Syllabus Details';
export const LEARNER_PROFILE = 'Learner Profile';
export const EDUCATOR_PROFILE = 'Educator Profile';
export const FREE_HOME_PAGE = 'Free Home Page';
export const CENTRE_GOAL_PAGE = 'Centre Goal Page';
export const ONE_TO_ONE_LIVE_MENTORSHIP = '1:1 LMP';
export const UPCOMING_BATCHES = 'Batch Group Upcoming List';
export const BATCH_ENROLLMENT = 'Batch Group - Enrollment';
export const PLATFORM_BATCH_PAGE = 'Batch Group Detail';
export const GOAL_LANDING_PAGE = 'Landing Page';
export const TRIAL_CLASSES = 'Trail Classes';
export const SEARCH_RESULT_PAGE = 'Search Results';
export const RANK_PREDICTOR = 'Rank Predictor';
export const DNS = 'Doubts and Solutions SEO';

// page names
export const EDUCATOR_CLASSES = 'Educator profile - Free Classes';
export const EDUCATOR_BATCHES = 'Educator profile - Batches';
export const EDUCATOR_COURSES = 'Educator profile - Courses';
export const EDUCATOR_ABOUTS = 'Educator profile - About';

export const FPS_LIST = [
  GOAL_PAGE,
  SCHEDULE_PAGE,
  SAVED_PAGE,
  ENROLLMENTS,
  SYLLABUS,
  ALL_TEST_SERIES,
  PRACTICE_PAGE,
  FREE_CLASSES_AND_TESTS,
  OTHER_COURSES,
  MY_LIBRARY,
  HELP_AND_SUPPORT,
  SETTINGS,
  ONE_TO_ONE_LIVE_MENTORSHIP
];

// List of only LPS
export const LPS_LIST = [
  PLANNER_PAGE,
  BATCH_PAGE,
  COURSE_PAGE,
  'Special Class',
  EDUCATOR_PROFILE,
  TEST_SERIES_PAGE,
  TOPIC_SYLLABUS_PAGE,
  TOPIC_COURSES_SYLLABUS_PAGE,
  'Free Live Class',
  LEARNER_PROFILE,
  UPCOMING_BATCHES,
  BATCH_ENROLLMENT,
  PLATFORM_BATCH_PAGE,
  FREE_HOME_PAGE,
  EDUCATOR_CLASSES,
  EDUCATOR_BATCHES,
  EDUCATOR_ABOUTS
];

// Lmp on web
export const LMP_PREFERENCES_ENTERED = 'Live Mentoring - Details Entered';
export const LMP_CURTAIN_CLOSED = 'Live Mentoring - Curtain Closed';
export const LMP_STEPPER_CLICKED = 'Live Mentoring - Stepper Clicked';
export const LMP_SESSION_TYPE_SELECTED =
  'Live Mentoring - Session Type Selected';
export const LMP_SESSION_DETAILS_SELECTED =
  'Live Mentoring - Subject Details Selected';
export const LMP_TIME_SLOT_SELECTED = 'Live Mentoring - Time Slot Selected';
export const LMP_IMAGES_ATTACHED = 'Live Mentoring - Images Attached';
export const LMP_SESSION_CONFIRMED = 'Live Mentoring - Session Confirmed';
export const LMP_BOOKING_CLICKED = 'Live Mentoring - Booking Clicked';
export const LMP_REPEAT_BOOKING = 'Live Mentoring - Repeat Booking Clicked';
export const LMP_SESSION_CANCELLED_REQUESTED =
  'Live Mentoring - Session Cancellation Requested';
export const LMP_SESSION_CANCELLED = 'Live Mentoring - Session Cancelled';
export const LMP_SEE_ALL_PAST_SESSION =
  'Live Mentoring - See All Past Sessions';
export const LMP_MENTOR_NOTE_VIEWED = 'LMP - Mentor Note Viewed';
export const LMP_RECORDING_CLICKED = 'LMP - Recording Clicked';
export const LMP_DETAILS_CLICKED = 'LMP - Details Clicked';
export const LMP_RESCHEDULE_CLICKED = 'Live Mentoring - Reschedule Clicked';
export const LMP_NO_SLOTS_FOUND = 'Live Mentoring - No Slots Found';
export const LMP_JOIN_SESSION_CLICKED = 'Live Mentoring - Join Session Clicked';
export const LMP_SESSION_EXITED = 'Live Mentoring - Session Exited';
export const MENTORSHIP_SECTION_VIEWED = 'Mentorship Section Viewed';
export const MENTORSHIP_CARD_CLICKED = 'Mentorship Card Clicked';

// Educator Live Mentoring on Web
export const EDUCATOR_LIVE_MENTORING_JOIN_SESSION_CLICKED =
  'Educator Live Mentoring - Join Session Clicked';
export const EDUCATOR_LIVE_MENTORING_UI_BACK_BUTTON_CLICKED =
  'Educator Live Mentoring - UI Back Button Clicked';
export const EDUCATOR_LIVE_MENTORING_SESSION_EXITED =
  'Educator Live Mentoring - Session Exited';
export const EDUCATOR_LIVE_MENTORING_SESSION_JOINED =
  'Educator Live Mentoring - Session Joined';
export const EDUCATOR_LIVE_MENTORING_SESSION_CLICKED =
  'Educator Live Mentoring - Session Clicked';
export const EDUCATOR_LIVE_MENTORING_CALL_CONNECT_CLICK =
  'Educator Live Mentoring - Call Connect Click';
export const EDUCATOR_LIVE_MENTORING_CALL_CONNECTED =
  'Educator Live Mentoring - Call Connected';
export const EDUCATOR_LIVE_MENTORING_CALL_CONNECTION_FAILED =
  'Educator Live Mentoring - Call Connection Failed';
export const EDUCATOR_LIVE_MENTORING_LEARNER_PROFILE_CLICKED =
  'Educator Live Mentoring - Learner Profile Clicked';
export const EDUCATOR_LIVE_MENTORING_LEARNER_CALL_INITIATED =
  'Educator Live Mentoring - Learner Call Initiated';
export const EDUCATOR_LIVE_MENTORING_LEARNER_CALL_FAILED =
  'Educator Live Mentoring - Learner Call Failed';
export const EDUCATOR_LIVE_MENTORING_NEXT_LEARNER_CLICKED =
  'Educator Live Mentoring - Next Learner Clicked';
export const EDUCATOR_LIVE_MENTORING_WEEKLY_LEARNER_REPORT_NAV_CLICKED =
  'Educator Live Mentoring - Weekly Learner Report Nav Clicked';

// Achievers card
export const ACHIEVERS_CAROUSEL_CARD_CLICKED =
  'Achievers Carousel - Card Clicked';

export const NOT_APPLICABLE = 'NA';

// Doubts on Web 2.0
export const DOW2_AAD_STARTED = 'Ask a Doubt - Started';
export const DOW2_SEE_ALL_CLICKED = 'Ask a Doubt - See All';
export const DOW2_DOUBT_SUBMIT_REQUESTED =
  'Ask a Doubt - Doubt Submit Requested';
export const DOW2_DOUBT_SUBMITTED = 'Ask a Doubt - Doubt Submitted';
export const DOW2_SOLUTION_SENT = 'Ask a Doubt - Solution Sent';
export const DOW2_SUBJECT_SELECTED = 'Ask a doubt - Doubt Subject Submit';
export const DOW2_FEEDBACK_SUBMITTED = 'Ask a Doubt - Feedback Submitted';
export const DOW2_FEEDBACK_CHOICES_SUBMITTED =
  'Ask a Doubt - Feedback Choices Submitted';
export const DOW2_ASK_AN_EDUCATOR = 'Ask a Doubt - Ask an Educator';
export const DOW2_SUBMIT_TO_EDUCATOR = 'Ask a Doubt - Submit to Educator';
export const DOW2_TRY_AN_EXAMPLE = 'Ask a Doubt - Try an Example';
export const DOW2_INVALID_DOUBT = 'Ask a Doubt - Inappropriate Doubt Content';
export const DOW2_TIMEOUT = 'Ask a Doubt - No Response Timeout';
export const DOW2_STATE_FILTER_APPLIED =
  'Ask a Doubt - Solution State Filter Applied';
export const DOW2_SUBJECT_FILTER_APPLIED =
  'Ask a Doubt - Subject Filter Applied';
export const DOW2_DAILY_LIMIT_REACHED = 'Ask a Doubt - Daily Limit Reached';
export const DOW2_PENDING_DOUBTS = 'Ask a Doubt - Pending Doubts';
export const DOW2_LAST_DOUBT_OF_DAY = 'Ask a Doubt - Last Doubt of Day';
export const DOW2_SOLUTION_REVIEW = 'Ask a Doubt - Solution Viewed';
export const DOW2_DOUBT_LANGUAGE_SELECTED =
  'Ask a doubt - Doubt language Selected';
export const DOW2_SUBJECT_CHANGED_CLICKED =
  'Ask a doubt - Doubt Subject Changed Clicked';
export const DOW2_TRANSCRIPT_VIEWED = 'Ask a doubt - Transcript Viewed';
export const DOW2_SOLUTION_CLICKED = 'Ask a doubt - Doubt Solution Clicked';
export const DOW2_LOGIN_BANNER_BUTTON_CLICKED =
  'Ask a doubt - Login Banner Button Clicked';
export const DOW2_SUBJECT_CHANGED = 'Ask a doubt - Doubt Subject Changed';
export const DOW2_QUESTION_VIEWED = 'Ask a doubt - Question Viewed';
export const DOW2_INSTANT_MATCH_FAILED = 'Ask a Doubt - Instant Match Failed';
export const DOW2_SOLUTION_DISPLAYED = 'Ask a Doubt - Solution Displayed';
export const DOW2_CONTINUE_CHAT_CLICKED = 'Ask a Doubt - Continue Chat Clicked';
export const DOW2_DOUBT_SUBMIT_FAILED = 'Ask a Doubt - Doubt Submit Failed';
export const DEVICE_LIMITATION_LOGGED_OUT = 'Device Limitation - Logged Out';
export const DEVICE_LIMITATION_MESSAGE_VIEWED =
  'Device Limitation - Message Viewed';
export const DEVICE_LIMITATION_RETRY_CLICKED =
  'Device Limitation - Retry Clicked';
export const DEVICE_LIMITATION_RETRY_SUCCESS =
  'Device Limitation - Retry Successful';
export const DEVICE_LIMITATION_RETRY_FAILED =
  'Device Limitation - Retry Failed';
export const DEVICE_LIMITATION_LOGOUT_OTHERS =
  'Device Limitation - Log Out Other Device Clicked';
export const DEVICE_LIMITATION_API_TIME_OUT =
  'Device Limitation - API Time Out';
export const PREDICT_NOW_CLICKED = 'Rank Prediction - Predict Now Clicked';
export const PREDICT_MY_RANK_CLICKED =
  'Rank Prediction - Predict My Rank Clicked';
export const PREDICTED_RANK_VIEWED = 'Rank Prediction - Predicted Rank Viewed';

export const COMMUNITY_BANNER_VIEWED = 'Community - Banner Viewed';
export const COMMUNITY_BANNER_CTA_CLICKED = 'Community - Banner CTA Clicked';

export const OPENHOUSE_BANNER_VIEWED = 'Openhouse - Banner Viewed';
export const OPENHOUSE_BANNER_CTA_CLICKED = 'Openhouse - Banner CTA Clicked';

// Autoschedule Plus Course Creation
export const COURSE_TEMPLATES_BANNER_VIEWED =
  'Course Templates - Banner Viewed';
export const CREATE_NEW_PLUS_COURSE_CLICKED =
  'Plus Course Creation - Create New Clicked';
export const PLUS_COURSE_GENERAL_DETAILS_SUBMITTED =
  'Plus Course Creation - General Details Submitted';
export const PLUS_COURSE_SPECIFICATIONS_SUBMITTED =
  'Plus Course Creation - Specifications Submitted';
export const PLUS_COURSE_SESSION_ADDED = 'Plus Course Creation - Session Added';
export const PLUS_COURSE_AUTO_SCHEDULED_CLICKED =
  'Plus Course Creation - Auto Schedule Clicked';
export const PLUS_COURSE_APPLY_SCHEDULED_CLICKED =
  'Plus Course Creation - Apply Schedule Clicked';
export const PLUS_COURSE_APPLY_SCHEDULE_SUCCESSFUL =
  'Plus Course Creation - Auto Schedule Successful';
export const PLUS_COURSE_APPLY_SCHEDULE_FAILED =
  'Plus Course Creation - Auto Schedule Failed';
export const PLUS_COURSE_EDIT_SESSION_CLICKED =
  'Plus Course Creation - Edit Session Clicked';
export const PLUS_COURSE_DELETE_SESSION_CLICKED =
  'Plus Course Creation - Delete Session Clicked';
export const PLUS_COURSE_COURSE_SUBMITTED =
  'Plus Course Creation - Course Submitted';
export const PLUS_COURSE_PREVIEW_CLICKED =
  'Plus Course Creation - Preview Clicked';
export const PLUS_COURSE_AWARENESS_BANNER_VIEWED =
  'Plus Course Creation - Banner Viewed';
export const PLUS_COURSE_AWARENESS_BANNER_CLICKED =
  'Plus Course Creation - Banner CTA Clicked';
export const PLUS_COURSE_TOPIC_NOT_COVERED =
  'Plus Course Creation - Topic Not Covered';
export const PLUS_COURSE_VIEW_DETAILS = 'Plus Course Creation - View Details';

// Educator Auto DPP
export const EDUCATOR_PRACTICE_BANNER_VIEWED =
  'Educator Practice - Banner Viewed';
export const EDUCATOR_PRACTICE_BANNER_CLICKED =
  'Educator Practice - Banner Clicked';
export const EDUCATOR_PRACTICE_CARD_VIEWED = 'Educator Practice - Card Viewed';
export const EDUCATOR_PRACTICE_CARD_CLICKED =
  'Educator Practice - Card Clicked';
export const EDUCATOR_PRACTICE_ADD_PRACTICE_CLICKED =
  'Educator Practice - Add Practice Clicked';
export const EDUCATOR_PRACTICE_PRACTICE_PUBLISHED =
  'Educator Practice - Practice Published';
export const EDUCATOR_PRACTICE_SAVED_AS_DRAFT =
  'Educator Practice - Saved As Draft';
export const EDUCATOR_PRACTICE_FILTERS_CHANGED =
  'Educator Practice - Filters Changed';
export const EDUCATOR_PRACTICE_QUESTION_SHUFFLED =
  'Educator Practice - Question Shuffled';
export const EDUCATOR_PRACTICE_QUESTION_REPORTED_BY_EDUCATOR =
  'Educator Practice - Question Reported By Educator';
export const EDUCATOR_PRACTICE_FEEDBACK_SUBMITTED =
  'Educator Practice - Feedback Submitted';
export const EDUCATOR_PRACTICE_FEEDBACK_OPTIONS_SUBMITTED =
  'Educator Practice - Feedback Options Submitted';
export const EDUCATOR_PRACTICE_PRACTICE_DELETED =
  'Educator Practice - Practice Deleted';
export const EDUCATOR_PRACTICE_VIEW_PRACTICE =
  'Educator Practice - View Practice';
export const CLASS_PRACTICES_TAB_VIEWED = 'Class Practices Tab Viewed';
export const EDUCATOR_PRACTICE_REVIEW_DRAFT_CLICKED =
  'Educator Practice - Review Draft Clicked';
export const EDUCATOR_PRACTICE_SHARE_IN_COMMUNITY_CLICKED =
  'Educator Practice - Share in Community Clicked';
export const PLUS_COURSES_TAB_VIEWED = 'Plus Courses Tab Viewed';
export const EDUCATOR_PRACTICE_STATS_VIEWED =
  'Educator Practice - Stats Viewed';

// LIST OF LPS - EDUCATOR AUTO DPP
export const EDUCATOR_DASHBOARD = 'Dashboard';
export const EDUCATOR_MY_SCHEDULE_PAGE = 'My schedule';
export const EDUCATOR_COURSE_PAGE = 'Plus sourses';
export const EDUCATOR_DPP_PRACTICE_PAGE = 'Auto - DPP Practice Page';
export const EDUCATOR_CLASS_PRACTICES_TAB = 'Class practices tab';

// COMMUNITY
export const COMMUNITY_SELECTED = 'Community - Goal Community Selected';
export const COMMUNITY_LINK_SHARED = 'Community - Community Link Shared';
export const COMMUNITY_GUIDEINES_VIEWED =
  'Community - Community Guidelines Viewed';
export const COMMUNITY_MESSAGE_SENT = 'Community - Message Sent';
export const COMMUNITY_MESSAGE_DELETED = 'Community - Message Deleted';
export const COMMUNITY_MESSAGE_REPORTED = 'Community - Message Reported';
export const COMMUNITY_MESSAGE_REACTION_ADDED =
  'Community - Reaction Added To Message';
export const COMMUNITY_LEARNER_PROFILE_CLICKED =
  'Community - Learner Profile Clicked';
export const COMMUNITY_LEARNER_MARKED_INACTIVE =
  'Community - Learner Marked Inactive';
export const COMMUNITY_ATTACH_CLICKED = 'Community - Add Attachment Clicked';
export const COMMUNITY_MESSAGE_COPIED = 'Community - Message Copied';
export const COMMUNITY_JUMP_LATEST_VIEWED = 'Community - Jump Latest Viewed';
export const COMMUNITY_JUMP_LATEST_CLICKED = 'Community - Jump Latest Clicked';
export const COMMUNITY_COMMENTS_VIEWED = 'Community - Message Comments Viewed';
export const COMMUNITY_COMMENTS_ACTIONED =
  'Community - Message Comments Actioned';
export const COMMUNITY_COMMENTS_MODE_CHANGED =
  'Community - Message Comments Mode Changed';
export const COMMUNITY_COMMENT_ADDED = 'Community - Comment Added to Message';
export const COMMUNITY_GROUP_VIEWED = 'Community - Group Viewed';

// RANK PREDICTOR
export const RANK_PREDICTOR_RANK_CLICKED =
  'Rank Predictor - Predict Rank Clicked';
export const RANK_PREDICTOR_RESULT_SHOWN = 'Rank Predictor - Result Shown';
export const RANK_PREDICTOR_AGAIN_CLICKED =
  'Rank Predictor - Predict Again Clicked';
export const RANK_PREDICTOR_RANK_FAILED =
  'Rank Predictor - Predict Rank Failed';

// EXCLUISVE COMMUNITY
export const COMMUNITY_MESSAGE_PREVIEW_CLICKED =
  'Community - Message Preview Clicked';
export const COMMUNITY_MESSAGE_RECO_VIEWED =
  'Community - Message Recommendation Viewed';

export const PLANNER_DATA_CORRUPTED = 'Planner - Data corruption';
export const PLANNER_IDB_NOT_REACHABLE = 'Planner - IDB NOT REACHABLE';
export const PLANNER_DIAGNOSIS = 'Planner - DIAGNOSIS';
export const PLANNER_DIAGNOSIS_ERROR = 'Planner - DIAGNOSIS - ERROR';
export const PLANNER_DIAGNOSIS_NO_DATA = 'Planner - DIAGNOSIS NO DATA';
export const PLANNER_SYNC_DELAY = 'Planner - SYNC DELAY';
export const PLANNER_DEBUG = 'Planner - DEBUG';

export const SPECIAL_CLASS_NOTIFY = 'Special Class Notify Me Clicked';

// platform specific events;
export const PLATFORM_ENROLLMENTS_VIEWED = 'Enrollments - Section Viewed';
export const PLATFORM_TAB_CLICKED = 'Tab - Batches Clicked';
export const BATCH_TAB_CLICKED = 'Batch - Batch Tab Clicked';
export const PLATFORM_BATCH_VIEWED = 'Batch - Batch Viewed';
export const PLATFORM_BATCH_CARD_CLICKED = 'Batch - Batch Clicked';
export const PLATFORM_BATCH_SHARED = 'Batch - Batch Shared';
export const PLATFORM_BATCH_FAQ_CLICKED = 'Batch - FAQ Clicked';
export const PLATFORM_BATCH_ABOUT_CLICKED = 'Batch - About Clicked';
export const PLATFORM_BATCH_SCHEDULE_CLICKED = 'Batch - Schedule Clicked';
export const PLATFORM_BATCH_VIDEO_PREVIEW_CLICKED =
  'Batch - Batch Video Preview';
export const PLATFORM_BATCH_SCHEDULE =
  'Batch Selection - View Full Schedule Clicked';
export const PLATFORM_EDUCATOR_PROFILE = 'Educator - Profile Viewed';
export const PLATFORM_BATCH_CHECK_STARTED = 'Checkout - Started';
export const PAYMENT_START_LEARNING_CLICKED =
  'Payment - Start Learning Clicked';
export const CHECKOUT_PAYMENT_SUCCESSFUL = 'Checkout - Payment Successful';

// course templates events
export const COURSE_TEMPLATES_SECTION_VIEWED =
  'Course Templates - Section Viewed';
export const COURSE_TEMPLATES_CREATE_TEMPLATE_CLICKED =
  'Course Templates - Create Template Clicked';

export const COURSE_TEMPLATES_COURSE_DETAILS_SAVED =
  'Course Templates - Course Details Saved';

export const COURSE_TEMPLATES_DELETE_TEMPLATE_CLICKED =
  'Course Templates - Delete Template Clicked';

export const COURSE_TEMPLATES_TEMPLATE_DELETED =
  'Course Templates - Template Deleted';

export const COURSE_TEMPLATES_ADD_SESSION_CLICKED =
  'Course Templates - Add Session Clicked';

export const COURSE_TEMPLATES_SESSION_ADDED =
  'Course Templates - Session Added';

export const COURSE_TEMPLATES_DELETE_SESSION_CLICKED =
  'Course Templates - Delete Session Clicked';

export const COURSE_TEMPLATES_SESSION_DELETED =
  'Course Tempates - Session Deleted';

export const COURSE_TEMPLATES_EDIT_SESSION_CLICKED =
  'Course Templates - Edit Session Clicked';

export const COURSE_TEMPLATES_SESSION_EDITED =
  'Course Templates - Session Edited';

export const COURSE_TEMPLATES_TEMPLATE_SAVED =
  'Course Templates - Template Saved';

export const COURSE_TEMPLATES_USE_TEMPLATE_CLICKED =
  'Course Templates - Use Template Clicked';

export const COURSE_TEMPLATES_FEEDBACK_SUBMITTED =
  'Course Templates - Feedback Submitted';
// GTP Events
export const GTP_SEE_ALL_CLICKED = 'See All - Clicked';
export const FILTER_APPLIED = 'Filter Applied';

// Platform Partner Events
export const PARTNER_ONBOARDING_PAGE_VIEW =
  'Partner Landing Page - Page Viewed';
export const PARTNER_ONBOARDING_REGISTER_NOW_CLICKED =
  'Partner Landing Page - Register Now Clicked';

// PWA Events
export const PWA_INFO = 'PWA - Info';

// Offline Centers
export const Offline_Post_Payment_Step_1_Landed =
  'Offline - Post Payment Step 1 Landed';

export const Offline_Post_Payment_Step_2_Landed =
  'Offline - Post Payment Step 2 Landed';

export const Offline_Post_Payment_Completed =
  'Offline - Post Payment Completed';

export const Offline_Back_to_Step_1 = 'Offline - Back to Step 1';

export const UNSAT_Page_Landed = 'UNSAT - Page Landed';

export const UNSAT_Registration_Submitted = 'UNSAT - Registration Submitted';

export const UNSAT_Registration_PI_Details = 'UNSAT - Registration PI Details';

// app download events
export const APP_INSTALL_CTA_CLICKED = 'App Install CTA clicked';

export const Offline_Answers_Attempt_Started =
  'Offline - Answers Attempt Started';

export const Offline_Credits_Questionaire_Submitted =
  'Offline - Credits Questionaire Submitted';

export const Banner_Banner_Viewed = 'Banner - Banner Viewed';

export const CONTINUE_ON_APP = 'Continue on app';

export const WEB_PAGE_VIEW_EVENT = 'Web Page View';

// Educator Web events
export const EDUCATOR_DETAILED_FEEDBACK_VIEWED =
  'Educator Detailed Feedback viewed';

export const EDUCATOR_DETAILED_CLASS_FEEDBACK_VIEWED =
  'Educator Detailed Class Feedback viewed';
