import styled from '@emotion/styled';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import {
  Spacings,
  Fonts,
  PRIMARY_BORDER_WIDTH,
  SOLID_BORDER_STYLE,
  breakpoints
} from '@styles/index';
import {
  UN_ICONS_BUTTON_COLOR,
  UN_ICONS_BUTTON_VARIANT
} from 'types/unacademyIcons/button.type';

const getWidth = (grid, width) => {
  if (width) return width;
  if (grid === 2) return '80px';
  if (grid === 3) return '128px';
  if (grid === 4) return '176px';
  if (grid > 4) return '100%';
};

const getHeight = (grid, maxHeight) => {
  // use maxheight or use according to grid
  if (maxHeight === 'xs') return Spacings.SPACING_8B;
  if (maxHeight === 'sm') return Spacings.SPACING_10B;
  if (maxHeight === 'lg') return Spacings.SPACING_14B;
  if (maxHeight === 'xl') return Spacings.SPACING_16B;
  if (grid === 2) return Spacings.SPACING_10B;
  return Spacings.SPACING_12B;
};

const getBackgroundColor = (variant, color) => {
  if (variant === 'hollow') return 'var(--color-base-1)';
  if (variant === 'disabled') return 'var(--color-shadow)';
  if (color === 'red') return 'var(--color-i-red)';
  if (color === 'green') return 'var(--color-i-green)';
  if (color === 'gold') return 'var(--color-i-icon-gold)';
  if (color === 'blue') return 'var(--color-i-blue)';
  return 'var(--color-text-primary)';
};

const getActiveBackgroundColor = (variant, color) => {
  if (variant === 'hollow') return 'var(--color-shadow)';
  if (variant === 'disabled') return 'var(--color-shadow)';
  if (color === 'green') return 'var(--color-i-green-focus)';
  if (color === 'red') return 'var(--color-i-red-focus)';
  if (color === 'blue') return 'var(--color-i-blue)';
};

const getBorderColor = (variant, color) => {
  if (variant === 'filled' && color === 'red') return 'var(--color-i-red)';
  if (variant === 'filled' && color === 'green') return 'var(--color-i-green)';
  if (variant === 'filled' && color === 'blue') return 'var(--color-i-blue)';
  if (variant === 'filled' && color === UN_ICONS_BUTTON_COLOR.GOLD)
    return 'var(--color-i-icon-gold)';
  return 'var(--color-divider)';
};

const getFontSize = (grid, variant) => {
  if (grid === 2) return Fonts.SMALL;
  if (grid === 3 || grid === 4) return Fonts.LARGE;
  if (variant === 'disabled') return Fonts.LARGE;
  return Fonts.SMALL;
};

const getTextColor = (variant, color) => {
  if (variant === 'filled' && color === 'green') return 'var(--color-i-white)';
  if (
    variant === UN_ICONS_BUTTON_VARIANT.FILLED &&
    color === UN_ICONS_BUTTON_COLOR.GOLD
  )
    return 'var(--color-i-icon-dark-tertiary)';
  if (variant === 'filled' && color === 'black') return 'var(--color-base-1)';
  if (variant === 'filled') return 'var(--color-i-white)';
  if (variant === 'disabled') return 'var(--color-text-secondary)';
  if (color === 'green') return 'var(--color-i-green)';
  if (color === 'blue') return 'var(--color-i-blue)';
  if (color === 'red') return 'var(--color-i-red)';
  if (color === 'black') return 'var(--color-text-primary)';
  return 'var(--color-text-primary)';
};

const StyledButton = styled.button`
  position: relative;
  border-width: ${({ variant }) =>
    variant === 'hollow' && PRIMARY_BORDER_WIDTH};
  border-style: ${({ variant }) => variant === 'hollow' && SOLID_BORDER_STYLE};
  border-color: ${({ variant, color }) => getBorderColor(variant, color)};
  border: ${({ variant }) =>
    (variant === 'filled' || variant === 'disabled') && 'none'};
  display: flex;
  justify-content: center;
  font-size: ${({ grid, variant }) => getFontSize(grid, variant)};
  font-weight: ${Fonts.BOLD};
  align-items: center;
  cursor: ${({ variant }) => (variant === 'disabled' ? 'no-drop' : 'pointer')};
  border-radius: ${Spacings.SPACING_6};
  width: ${({ grid, width }) => getWidth(grid, width)};
  height: ${({ grid, maxHeight }) => getHeight(grid, maxHeight)};
  min-height: ${({ grid, maxHeight }) => getHeight(grid, maxHeight)};
  color: ${({ variant, color }) => getTextColor(variant, color)};
  -webkit-user-drag: none;
  user-select: none;
  background-color: ${({ variant, color }) =>
    getBackgroundColor(variant, color)};
  transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out,
    background-color 300ms ease-in-out;
  &:hover {
    box-shadow: ${({ variant }) =>
      variant !== 'disabled' && boxShadowGenerator()};
    transform: ${({ variant }) =>
      variant !== 'disabled' && 'translate(0px, -2px)'};
    border-color: ${({ variant }) =>
      variant === 'hollow' && 'var(--color-divider)'};
    ${({ variant }) =>
      variant === 'hollow' ? 'background-color: var(--color-divider);' : ''}
  }
  &:active {
    border: ${({ variant }) => variant !== 'hollow' && 'none'};
    box-shadow: ${boxShadowGenerator({
      yOffset: Spacings.SPACING_2B,
      blur: Spacings.SPACING_2B
    })};
    background-color: ${({ variant, color }) =>
      getActiveBackgroundColor(variant, color)};
  }
`;

const IconWrapper = styled.div`
  margin-right: ${({ icon }) => icon && Spacings.SPACING_2B};
  display: flex;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: inline-block;
  }
`;

const Button = forwardRef(
  (
    {
      label,
      variant,
      color,
      onClick,
      onMouseEnter,
      onMouseLeave,
      className,
      grid,
      icon,
      type,
      maxHeight,
      dataAnalytics,
      // eslint-disable-next-line react/prop-types
      width,
      // eslint-disable-next-line react/prop-types
      boxShadow,
      style = {}
    },
    ref
  ) => {
    return (
      <StyledButton
        type={type}
        aria-label={label}
        ref={ref}
        variant={variant}
        color={color}
        grid={grid}
        maxHeight={maxHeight}
        width={width}
        className={className}
        onClick={variant === 'disabled' ? null : onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-analytics={dataAnalytics}
        boxShadow={boxShadow}
        style={style}
      >
        <>
          {icon && <IconWrapper icon={icon}>{icon}</IconWrapper>}
          {label}
        </>
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['hollow', 'filled', 'disabled']),
  color: PropTypes.oneOf(['red', 'green', 'black', 'blue', 'gold']),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.node]),
  className: PropTypes.string,
  maxHeight: PropTypes.oneOf(['sm', 'xs', 'xl', 'lg']),
  grid: PropTypes.number,
  type: PropTypes.string,
  dataAnalytics: PropTypes.string
};

Button.defaultProps = {
  variant: 'filled',
  color: 'green',
  className: '',
  icon: null,
  grid: 4,
  maxHeight: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  label: '',
  type: 'button',
  dataAnalytics: ''
};
