const BASE_DARK_THEME_COLORS = `
--color-base-light-0: #242526;

--color-text-tertiary: #121212;
--color-text-secondary-light: #A2A0A2;

--color-overlay: rgba(0, 0, 0, 0.3);

--color-primary-focus: #DADADA;
--color-green-focus: #00965;
--color-red-border-focus: #251C1C;
--color-red-fill-focus: #C53D3D;

--color-progress-grey: #CFCFCF;
--color-progress-grey-secondary: #A6A6A7;
--color-progress-base: #7C7D7E;
--color-progress-base-secondary: #535456;
--color-purple: rgba(114, 96, 224, 0.15);
--color-purple-dark: #7260E0;
`;
const themeConfig = {
  light: `
      --filter: 0;
      --theme: light;
      --color-opacity: 1;

      --color-base-0: #FCFCFC;
      --color-base-1: #FFFFFF;
      --color-base-light-0: #F7F9FB;
      --color-base-light-0-secondary: #F7F9FB;
      --color-base-fill: #3C4852;

      --color-text-primary: #3C4852;
      --color-text-secondary: #7A8B94;
      --color-text-tertiary: #FFFFFF;
      --color-text-secondary-light: #A6B3BA;
      --color-text-primary-light: #7A8B94;

      --color-shadow: rgba(233, 238, 242, 0.4);
      --color-shadow2: rgba(221, 230, 237, 0.4);
      --color-overlay: rgba(0, 0, 0, 0.5);
      --color-divider: #E9EEF2;
      --color-divider-secondary: #F0F4F7;
      --color-divider-tertiary: #3C4852;

      --color-primary-focus: #1E2429;
      --color-green-focus: #00965;
      --color-red-border-focus: #FEF5F5;
      --color-red-fill-focus: #C53D3D;
      --color-background:#FFF4E5;
      --color-progress-grey: #636D75;
      --color-progress-grey-secondary: #8A9197;
      --color-progress-base: #B1B6BA;
      --color-progress-base-secondary: #D8DADC;
      --color-purple: #E5E1FF;
      --color-purple-dark: #7F6EFC;
      --color-progress-base-primary: #000000;
      --color-purple-secondary: rgba(152, 117, 255, 0.1);
      --color-grey-background: #595959;

      --color-orange-1: #FFF5E5;

      --color-brown-1: #522C2C;
      --color-brown-2: #522C2C;
      --color-brown-3:  #A46840;

      --color-violet-1: #F7F9FB;
      --color-violet-2: #6B6D9C;

      --color-yellow-1: #B2863B;

      --color-blue-1: #45889F;

      --color-educator-bg: #F4F4F4;

      --color-accent-blue: #2d81f7;

      --color-red-1:#D84141;
      
      --color-red-2:#FFD8D8;

      --color-green-2: #08BD80;

      --color-grey-1: #e6e6e6;
    
      `,
  dark: `
      --filter: 1;
      --theme: dark;
      --color-opacity: 1;

      --color-base-0: #242526;
      --color-base-1: #2A2B2D;
      --color-base-light-0: #242526;
      --color-base-light-0-secondary: #2A2B2D;
      --color-base-fill: #F8F8F8;

      --color-text-primary: #F4F4F3;
      --color-text-secondary: #A2A0A2;
      --color-text-tertiary: #121212;
      --color-text-secondary-light: #A2A0A2;
      --color-text-primary-light: #F4F4F3;
      --color-text-primary-dark: #FFFFFF;

      --color-shadow: rgba(18, 18, 18, 0.2);
      --color-divider: #424244;
      --color-divider-secondary: #424244;
      --color-divider-tertiary: #FFFFFF;
      --color-overlay: rgba(0, 0, 0, 0.3);

      --color-primary-focus: #DADADA;
      --color-green-focus: #00965;
      --color-red-border-focus: #251C1C;
      --color-red-fill-focus: #C53D3D;

      --color-progress-grey: #CFCFCF;
      --color-progress-grey-secondary: #A6A6A7;
      --color-progress-base: #7C7D7E;
      --color-progress-base-secondary: #535456;
      --color-purple: rgba(114, 96, 224, 0.15);
      --color-purple-dark: #7260E0;

      --color-orange-1: #3A342A;

      --color-brown-1: #FFFFFF;
      --color-brown-2: #DBC69D;
      --color-brown-3: #D48754;

      --color-violet-1: rgba(45, 129, 247, 0.07);
      --color-violet-2: #9A9CE3;

      --color-yellow-1: #E2AF58;

      --color-blue-1: #67B4CF;

      --color-educator-bg: #3A3A3B;

      --color-accent-blue: #2d81f7;

      --color-red-1:#D84141;

      --color-red-2:#FFD8D8;

      --color-green-2: #08BD80;

      --color-grey-1: #e6e6e6;

      `,
  uaicons: `
  --filter: 0;
  --theme: uaicons;
  
  --color-base-0: #101010;
  --color-base-1: #101010;
  --color-base-fill: #3C4852;

  --color-text-primary: #F4F4F3;
  --color-text-secondary: #A2A0A2;

  --color-shadow: #0909095c;
  --color-divider: #2A2B2D;

  --color-progress-base-primary: #F4F4F3;

  --color-live-tag: #FF0000;

  /* Unacademy dark theme colors  */
  ${BASE_DARK_THEME_COLORS}
  `
};

export default themeConfig;
